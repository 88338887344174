<template>
  <div :class="classes" class="w-full sm:w-auto">
    <div class="relative top-0 w-full">
      <ButtonDialog
        :v-if="props.dialogContent"
        :dialog-content="dialogContentRef"
        :visible="showDialogRef"
        :voucher-button="voucherButton"
        @close="emit('closeDialog')"
      >
        <template v-if="$slots.dialogContent" #content>
          <slot name="dialogContent" />
        </template>
      </ButtonDialog>
      <button
        v-if="type === EButtonType.BUTTON || type === EButtonType.SUBMIT"
        :type="type"
        class="flex w-full text-lg custom-button minilu-button font-pero sm:w-auto"
        :class="[onlyIcon ? ' py-[7px] px-[25px] ' + btnClasses : btnClasses]"
        :style="btnStyles"
        :disabled="disabled"
        :data-testid="testid ?? ''"
        @click="emit('click')"
      >
        <template v-if="$slots.label">
          <slot name="label" />
        </template>
        <template v-else>
          {{ label }}
        </template>
        <FaIcon v-if="props.icon" :icon-class="props.icon" />
        <span v-if="loadingAnimation">
          <FaIcon icon-class="fas fa-circle-notch fa-spin mx-2xs" />
        </span>
      </button>
    </div>

    <NuxtLink
      v-if="type === EButtonType.ROUTERLINK"
      class="!inline-flex custom-button minilu-button"
      :class="btnClasses"
      :disabled="disabled"
      :to="target"
      @click="emit('click')"
    >
      <template v-if="$slots.label">
        <slot name="label" />
      </template>
      <template v-else>
        {{ label }}
      </template>
      <FaIcon v-if="props.icon" :icon-class="props.icon" />
      <span v-if="loadingAnimation">
        <FaIcon icon-class="fas fa-circle-notch fa-spin mx-2xs" />
      </span>
    </NuxtLink>

    <a
      v-if="type === EButtonType.LINK"
      class="!inline-flex custom-button minilu-button"
      :class="btnClasses"
      :disabled="disabled"
      :href="target"
      :style="btnStyles"
      @click="emit('click')"
    >
      <template v-if="$slots.label">
        <slot name="label" />
      </template>
      <template v-else>
        {{ label }}
      </template>
      <FaIcon v-if="props.icon" :icon-class="props.icon" />
      <span v-if="loadingAnimation">
        <FaIcon icon-class="fas fa-circle-notch fa-spin mx-2xs" />
      </span>
    </a>
  </div>
</template>

<script setup lang="ts">
/**
 * @TODO: Lazyload v-if (ButtonDialog)
 */
import { toRef } from 'vue';
import { EPosition, EButtonStyle, EButtonType } from '@/@types/basic-button';
import type { IDialogContent } from '@/@types/dialog-content';
import ButtonDialog from '@/components/components/core/basic-button-dialog/basic-button-dialog.vue';
import FaIcon from '@/components/fa-icon.vue';
/** Props */
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  type: {
    type: String,
    default: EButtonType.BUTTON,
  },
  target: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: null,
  },
  onlyIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  iconPosition: {
    type: String,
    default: EPosition.RIGHT,
  },
  voucherButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnStyle: {
    type: String,
    default: EButtonStyle.DEFAULT,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  slim: {
    type: Boolean,
    default: true,
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  btnClass: {
    type: String,
    required: false,
    default: '',
  },
  showDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
  dialogContent: {
    type: Object as PropType<IDialogContent>,
    required: false,
    default: () => null,
  },
  bgColor: {
    type: String,
    required: false,
    default: '',
  },
  loadingAnimation: {
    type: Boolean,
    required: false,
    default: false,
  },
  noBorderRadius: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnHeight: {
    type: String,
    required: false,
    default: '',
  },
  testid: {
    type: String,
    required: false,
    default: '',
  },
});

/** Emits */
const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'closeDialog'): string;
}>();

const showDialogRef = toRef(props, 'showDialog');
const dialogContentRef = toRef(props, 'dialogContent');

const btnStyles = computed(() => {
  const styles = [];
  if (props.bgColor.length) {
    styles.push({ backgroundColor: props.bgColor });
  }
  if (props.noBorderRadius) {
    styles.push({ borderRadius: '0 !important' });
  }
  if (props.btnHeight.length) {
    styles.push({ height: props.btnHeight });
  }
  return styles;
});

const btnClasses = [
  {
    'flex-row-reverse': props.iconPosition == EPosition.LEFT,
    '!w-full': props.fullWidth,
    'custom-button-slim': props.slim,
    '!font-normal': props.btnStyle == 'inverted',
  },
  `minilu-button--${props.btnStyle}`,
  props.btnClass,
];
</script>
<style scoped lang="postcss">
/** MINILU BUTTONS **/
.minilu-button--cta {
  @apply text-white bg-minilu-btn-cta;
}

.minilu-button--cta:disabled {
  @apply text-minilu-border-lighter bg-btn-cta-base-opc50;
}
.minilu-button--cta-dark {
  @apply bg-minilu-btn-base text-white;
}

.minilu-button--cta-dark:disabled {
  @apply bg-minilu-btn-alpha text-minilu-border-light;
}

.minilu-button--formrepeater {
  @apply text-white bg-minilu-btn-light;
}

.minilu-button--formrepeater:disabled {
  @apply text-white bg-minilu-btn-lighter;
}

.minilu-button--formrepeaterComplaintForm {
  @apply text-[#322C38] bg-[#FFFFFF80];
}

.minilu-button--formrepeater:disabled {
  @apply text-white bg-minilu-btn-lighter;
}

.minilu-button--default {
  @apply text-white bg-minilu-btn-base;
}

.minilu-button--default:disabled {
  @apply bg-minilu-btn-alpha text-minilu-border-light;
}

.minilu-button--light {
  @apply bg-minilu-btn-light text-minilu-font;
}

.minilu-button--light:disabled {
  @apply text-minilu-border-light;
}

.minilu-button--inverted {
  @apply text-white bg-minilu-btn-light;
}

.minilu-button--inverted:disabled {
  @apply bg-minilu-btn-light text-minilu-border-light;
}
</style>
